import {Injectable} from '@angular/core';
import {ReplaySubject} from 'rxjs';
import {PodioOrgApiModel, PodioService} from "api-client";

@Injectable()
export class PodioOrgsService {

    private dataObs$ = new ReplaySubject<PodioOrgApiModel[]>(1);

    constructor(private podioService: PodioService) {
    }

    /**
     *
     * @param forceRefresh
     * @returns {ReplaySubject} Note that this cannot be transformed to a promise!
     */
    getPodioOrgs(forceRefresh?: boolean): ReplaySubject<PodioOrgApiModel[]> {
        if (!this.dataObs$.observers.length || forceRefresh) {
            this.podioService.getOrgs().subscribe(
                data => this.dataObs$.next(data),
                error => {
                    this.dataObs$.error(error);
                    // Recreate the Observable as after Error we cannot emit data anymore
                    this.dataObs$ = new ReplaySubject(1);
                }
            );
        }

        return this.dataObs$;
    }

}
