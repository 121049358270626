import {Injectable} from '@angular/core';
import {ConstantsService} from './constants.service';

@Injectable()
export class UrlService {

    constructor(private constants: ConstantsService) {
    }

    public createApiUrl(collection: string,
                        iteration: string = null,
                        org: string = null,
                        space: string = null,
                        app: string = null) {
        let url = `${this.constants.baseUrl}/backupcollection/${encodeURIComponent(collection)}`;
        if (iteration) {
            url += `/backupiteration/${encodeURIComponent(iteration)}`;
            if (org) {
                url += `/org/${encodeURIComponent(org)}`;
                if (space) {
                    url += `/space/${encodeURIComponent(space)}`;
                    if (app) {
                        url += `/app/${encodeURIComponent(app)}`;
                    }
                }
            }
        }
        return url;
    }

    /**
     * @deprecated
     * @param value
     * @returns {string}
     */
    public encode(value: string) {
        return encodeURIComponent(value).replace(/%2F/g, '%252F').replace(/%5C/g, '%255C');
    }

    /**
     *
     * @param param
     * @returns {string}
     */
    public decode(param: string) {
        return decodeURIComponent(param.replace(/%255C/g, '%5C').replace(/%252F/g, '%2F'));
    }
}
