import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CopyItemComponent} from './copy-item.component';
import {CloseReason} from '../../close-reason.enum'
import {CopyNamespaceApiModel} from 'api-client';

class ConfirmCopyItem {
  constructor(public confirmed: boolean,
              public selectedNamespace: CopyNamespaceApiModel) {
  }
}

@Injectable()
export class CopyItemService {

  private static clearFocus() {
    // see https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
    (<any>document.activeElement).blur();
  }

  constructor(private modalService: NgbModal) {
  }

  /**
   * @returns {Promise<boolean>} Promise, true if dialog is confirmed
   */
  confirmCopyItem(copyNamespaces: CopyNamespaceApiModel[], isBatch: boolean, itemCount = null): Promise<ConfirmCopyItem> {
    CopyItemService.clearFocus();

    const dialog = this.modalService.open(CopyItemComponent);
    const instance = dialog.componentInstance;
    instance.copyNamespaces = copyNamespaces;
    instance.isBatch = isBatch;
    instance.itemCount = itemCount;

    return new Promise<ConfirmCopyItem>((resolve) => {
      dialog.result
        .then(result => resolve(new ConfirmCopyItem(result === CloseReason.CONFIRM, instance.selectedNamespace)))
        .catch(error => {
          console.log('error: ', error);
          resolve(new ConfirmCopyItem(false, null))
        });
    });
  }
}
