<h4 class="card-title p-4">
  Copy App {{includingItems ? 'and Items' : ''}}
  <button type="button" class="close pull-right" aria-label="Close"
          (click)="activeCopyAppModal.dismiss(CloseReason.CLOSE)">
    <span aria-hidden="true">&times;</span>
  </button>
</h4>
<div class="card-body px-4 pt-4">
  <form name="form" #f="ngForm" novalidate>
    <div class="card-text pb-2">
      This copies the app structure (configuration and fields) {{includingItems ? 'and all' : 'but it contains no'}} items.
    </div>
    <div class="card-text pb-2">
      <div>
        <input type="radio" [value]=true class="form-check-input me-1" id="createNewCopy" name="createNewCopy"
               [(ngModel)]="createNamespace" (change)="triggerValidation()">
        <label class="form-check-label" for="createNewCopy">Create new namespace</label>
      </div>

      <div>
        <input type="radio" [value]=false class="form-check-input me-1" id="useExistingCopy" name="createNewCopy"
               (change)="triggerValidation()" [(ngModel)]="createNamespace"
               [attr.disabled]="!copyNamespaces.length? '' : null">
        <label class="form-check-label" for="useExistingCopy">Use existing namespace</label>
      </div>
    </div>

    <div *ngIf="createNamespace" class="mb-3 required">
      <label for="nameSpaceCopy"> Namespace of the copy</label>
      <input type="text" [(ngModel)]="newNamespace" name="name-new-copy" id="nameSpaceCopy"
             placeholder="Namespace"
             class="form-control" required>
      <small class="form-text text-muted">
        Please give this copy a name, so that subsequent copies of this or other related/referenced apps and items can be
        linked to this copy.
      </small>
    </div>

    <div class="mb-3 required">
      <div class="mb-2"> Where do you want the copy to be made?</div>
      <div *ngIf="createNamespace" class="input-group mb-3">
        <div class="input-group-prepend copy-app-label">
          <label for="selectedOrd"> Organization </label>
        </div>
        <div ngbDropdown id="selectedOrd">
          <button class="btn btn-outline-primary" ngbDropdownToggle id="dropdownOrgs">
            <ng-container *ngIf="this.loadingPodioOrgs">
              loading...
            </ng-container>
            <ng-container *ngIf="!this.loadingPodioOrgs && !selected_org">
              Please select an organization
            </ng-container>
            <ng-container *ngIf="selected_org && !this.loadingPodioOrgs">
              {{selected_org.label}}
            </ng-container>
          </button>
          <div ngbDropdownMenu aria-labelledby="dropdownOrgs">
            <button class="dropdown-item" *ngFor="let org of orgs" (click)="changeSpaces(org)">{{org.label}}</button>
          </div>
        </div>
      </div>

      <div *ngIf="createNamespace && !this.loadingPodioOrgs && selected_org" class="input-group mb-3 required">
        <div class="input-group-prepend copy-app-label">
          <label for="selectedSpace"> Space </label>
        </div>
        <div ngbDropdown id="selectedSpace">
          <ng-container *ngIf="!selected_space">
            <button class="btn btn-outline-primary" ngbDropdownToggle>Please select a space</button>
          </ng-container>
          <ng-container *ngIf="selected_space">
            <button class="btn btn-outline-primary" id="dropdownSpaces"
                    ngbDropdownToggle>{{selected_space.label}}</button>
          </ng-container>
          <div ngbDropdownMenu aria-labelledby="dropdownSpaces">
            <span *ngFor="let space of spaces"
                  ngbPopover="{{'The space has already an app with the name ' + this.appName + '.' }}"
                  container="body"
                  triggers="mouseenter:mouseleave" placement="right"
                  [disablePopover]="!space.app_exists">
              <button class="dropdown-item"
                      (click)="setSelectedSpace(space)"
                      [ngClass]="{'disabled' : space.app_exists}">{{space.label}}</button>
            </span>
          </div>
        </div>
        <small *ngIf="alreadyExistingNamespace" class="form-text text-muted hint-existing-namespace">
          The namespace '{{alreadyExistingNamespace}}' already copies apps to {{selected_space['label']}}.
          Please consider using the 'Use existing namespace' option.
        </small>
      </div>

      <div *ngIf="!createNamespace" class="input-group mb-3 d-block">
        <div ngbDropdown>
          <ng-container *ngIf="!namespaceIsSelected()">
            <button class="btn btn-outline-primary" ngbDropdownToggle>Please select a namespace for the copy</button>
          </ng-container>
          <ng-container *ngIf="namespaceIsSelected()">
            <button class="btn btn-outline-primary" id="dropDownSelectLogicalRestore"
                    ngbDropdownToggle>{{copyNamespace.copy_namespace}}</button>
          </ng-container>
          <div ngbDropdownMenu aria-labelledby="dropDownSelectLogicalRestore">
            <span *ngFor="let namespace of copyNamespaces"
                  ngbPopover="{{'The space has already an app with the name ' + this.appName + '.'}}"
                  container="body"
                  triggers="mouseenter:mouseleave" placement="right"
                  [disablePopover]="namespace.copyIsAllowed">
              <button class="dropdown-item"
                      [ngClass]="{'disabled' : !namespace.copyIsAllowed}"
                      (click)="setExistingCopyNamespace(namespace)">{{namespace.copy_namespace}}</button>
            </span>
          </div>
        </div>
        <small *ngIf="!createNamespace && namespaceIsSelected()" class="form-text text-muted">
          Organization: {{copyNamespace.target_org_name}}
          <br>
          Space: {{copyNamespace.target_space_name}}
        </small>
      </div>
    </div>

    <div class="mb-3">
      Do you want to copy the app<ng-container *ngIf="includingItems"> and all items</ng-container>?
      <ng-container *ngIf="!createNamespace"> This may overwrite changes! </ng-container>
      <div class="text-end">
        <ng-container>
           <span
             [disablePopover]="f.form.valid && validate"
             class="d-inline-block"
             ngbPopover="{{createNamespace ? 'Enter a namespace, select organization and space' : 'Select a namespace'}}"
             placement="bottom"
             triggers="mouseenter:mouseleave">
            <button (click)="clickSubmit()"
                    [disabled]="!f.form.valid || !validate"
                    class="btn btn-primary me-1"
                    type="button">OK
            </button>
          </span>
          <button (click)="activeCopyAppModal.close(CloseReason.CANCEL)" class="btn btn-outline-secondary"
                  type="button">Cancel
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
