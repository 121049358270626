import {Injectable} from '@angular/core';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {CopyAppComponent} from './copy-app.component';
import {CloseReason} from '../../close-reason.enum'
import {CopyNamespaceApiModel} from 'api-client';


class ConfirmCopyApp {
  constructor(public confirmed: boolean,
              public createNamespace: boolean,
              public copyNamespace: CopyNamespaceApiModel) {
  }
}

@Injectable()
export class CopyAppService {

  private static clearFocus() {
    // see https://github.com/ng-bootstrap/ng-bootstrap/issues/1252
    (<any>document.activeElement).blur();
  }

  constructor(private modalService: NgbModal) {
  }

  /**
   * @returns {Promise<boolean>} Promise, true if dialog is confirmed
   */
  confirmCopyApp(appName: string, space: string, copyNamespaces: CopyNamespaceApiModel[], includingItems: boolean): Promise<ConfirmCopyApp> {
    CopyAppService.clearFocus();

    const dialog = this.modalService.open(CopyAppComponent);
    const instance = dialog.componentInstance;
    instance.appName = appName;
    instance.appSpace = space;
    instance.copyNamespaces = copyNamespaces;
    instance.includingItems = includingItems;

    return new Promise<ConfirmCopyApp>((resolve) => {
      dialog.result
        .then(result => {
          resolve(new ConfirmCopyApp(result === CloseReason.CONFIRM, instance.createNamespace, instance.copyNamespace))
        })
        .catch(error => {
          console.log('error: ', error);
          resolve(new ConfirmCopyApp(false, null, null))
        });
    });
  }
}
