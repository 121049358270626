import {Component, OnInit} from '@angular/core';
import { NgbActiveModal, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {CloseReason} from '../../close-reason.enum'
import {CopyNamespaceApiModel} from 'api-client';
import { NgIf, NgFor } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-copy-item',
    templateUrl: './copy-item.component.html',
    styleUrls: ['../../modal-content.scss', './copy-item.component.scss'],
    standalone: true,
    imports: [FormsModule, NgbDropdown, NgIf, NgbDropdownToggle, NgbDropdownMenu, NgFor, NgbPopover]
})
export class CopyItemComponent implements OnInit {
  CloseReason = CloseReason;
  copyNamespaces: CopyNamespaceApiModel[] = [];
  selectedNamespace: CopyNamespaceApiModel = null;
  isBatch: boolean;
  itemCount: number;

  constructor(public activeCopyItemModal: NgbActiveModal) { }

  ngOnInit() {
    this.copyNamespaces = this.copyNamespaces.filter(namespace => {
        return namespace.appCopied;
      }
    )
  }

  namespaceIsSelected(): boolean {
    return this.selectedNamespace !== null;
  }

  setSelectedNamespace(namespace: CopyNamespaceApiModel) {
    this.selectedNamespace = namespace;
  }

}
