import {Component, OnInit} from '@angular/core';
import { NgbActiveModal, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgbPopover } from '@ng-bootstrap/ng-bootstrap';
import {PodioOrgsService} from '../../backup/podio-orgs.service';
import {NotificationService} from '../../notification.service';
import {CloseReason} from '../../close-reason.enum'
import {combineLatest, Observable} from 'rxjs';
import {CopyNamespaceApiModel, PodioOrgApiModel, PodioService, PodioSpaceApiModel} from "api-client";
import { NgIf, NgFor, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'app-copy-app-modal-content',
    styleUrls: ['../../modal-content.scss', './copy-app.scss'],
    templateUrl: './copy-app.html',
    standalone: true,
    imports: [FormsModule, NgIf, NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, NgFor, NgbPopover, NgClass]
})
export class CopyAppComponent implements OnInit {
  appName: string;
  appSpace: string;
  newNamespace = '';
  createNamespace = true;
  CloseReason = CloseReason;
  orgs: { value: PodioOrgApiModel, label: string, id: number }[];
  spaces: { app_exists: boolean; label: string; id: number; value: PodioSpaceApiModel }[];
  selected_org: { value: PodioOrgApiModel; label: string; id: number } | null = null;
  validate = false;
  selected_space: { app_exists: boolean; label: string; id: number; value: PodioSpaceApiModel } | null = null;
  loadingPodioOrgs = true;
  copyNamespaces: CopyNamespaceApiModel[] = [];
  copyNamespace: CopyNamespaceApiModel = null;
  alreadyExistingNamespace = '';
  includingItems: boolean;
  private spacesContainingApp: number[];

  private static mapOrgs(list: PodioOrgApiModel[]) {
    return list.map(o => ({value: o, label: o.name, id: o.org_id}));
  }

  constructor(public activeCopyAppModal: NgbActiveModal,
              private podioOrgsService: PodioOrgsService,
              private notify: NotificationService,
              private podioService: PodioService) {
  }

  changeSpaces(org: { value: PodioOrgApiModel; label: string; id: number }) {
    this.selected_org = org;
    this.spaces = this.mapSpaces(this.selected_org.value.spaces);
    this.selected_space = null;
    this.validate = false;
    this.alreadyExistingNamespace = '';
  }

  setSelectedSpace(space: {
    app_exists: boolean;
    label: string;
    id: number;
    value: PodioSpaceApiModel
  }) {
    this.alreadyExistingNamespace = '';
    this.selected_space = space;
    this.validate = true;
    this.copyNamespaces.forEach(copyNamespace => {
      if (copyNamespace.target_space_id === this.selected_space['id'] && copyNamespace.target_org_id === this.selected_org?.id) {
        this.alreadyExistingNamespace = copyNamespace.copy_namespace;
      }
    });
  }

  triggerValidation() {
    if (this.createNamespace) {
      this.validate = !!this.selected_space;
    } else {
      this.validate = this.namespaceIsSelected();
    }
  }

  clickSubmit() {
    if (this.createNamespace) {
      this.copyNamespace = {
        copy_namespace: this.newNamespace,
        target_org_id: this.selected_org?.id,
        target_org_name: this.selected_org?.label,
        target_space_id: this.selected_space?.id,
        target_space_name: this.selected_space?.label
      };
    }
    this.activeCopyAppModal.close(CloseReason.CONFIRM);
  }

  private getSpacesContainingApp(): Observable<number[]> {
    return this.podioService.getSpacesContainingApp(this.appName);
  }

  setExistingCopyNamespace(name: CopyNamespaceApiModel) {
    this.copyNamespace = name;
    this.validate = true;
  }

  private getCopyNamespaces() {
    if (this.copyNamespaces.length) {
      this.copyNamespaces.forEach(copyNamespace => {
        copyNamespace.copyIsAllowed = (!this.spacesContainingApp.includes(copyNamespace.target_space_id) || copyNamespace.appCopied);
      });
    }
  };


  ngOnInit() {
    const podioOrgs$ = this.podioOrgsService.getPodioOrgs();
    const spacesContainingApp$ = this.getSpacesContainingApp();

    combineLatest(
      [podioOrgs$,
        spacesContainingApp$]
    ).subscribe(([podioOrgs, spacesContainingApp]) => {
        this.orgs = CopyAppComponent.mapOrgs(podioOrgs);
        this.loadingPodioOrgs = false;
        this.spacesContainingApp = spacesContainingApp;
        this.getCopyNamespaces();
      }, error => {
        this.notify.notify(error.message, 'Could not load Podio Organizations/Spaces!', 'error');
        this.loadingPodioOrgs = false;
      }
    );
  }

  private mapSpaces(list: PodioSpaceApiModel[]) {
    return list.map(o => ({
      value: o,
      label: o.name,
      id: o.space_id,
      app_exists: this.spacesContainingApp.includes(o.space_id)
    }));
  }

  public namespaceIsSelected(): boolean {
    return this.copyNamespace !== null;
  }

}
