<h4 class="card-title p-4">
  Copy {{isBatch ? 'Items' : 'Item'}}
  <button (click)="activeCopyItemModal.dismiss(CloseReason.CLOSE)" aria-label="Close" class="close pull-right"
          type="button">
    <span aria-hidden="true">&times;</span>
  </button>
</h4>
<div class="card-body px-4 pt-4">
  <form #f="ngForm" name="form" novalidate>
    <div class="mb-3 required">
      <label for="selectNamespace"> Where do you want the copy to be made? </label>
      <div class="input-group mb-3 d-block" id="selectNamespace">
        <div ngbDropdown>
          <ng-container *ngIf="!namespaceIsSelected()">
            <button class="btn btn-outline-primary" ngbDropdownToggle>Please select a namespace for the copy</button>
          </ng-container>
          <ng-container *ngIf="namespaceIsSelected()">
            <button class="btn btn-outline-primary" id="dropDownSelectNamespace"
                    ngbDropdownToggle>{{selectedNamespace.copy_namespace}}</button>
          </ng-container>
          <div aria-labelledby="dropDownSelectNamespace" ngbDropdownMenu>
            <span *ngFor="let copyNamespace of copyNamespaces">
              <button (click)="setSelectedNamespace(copyNamespace)" class="dropdown-item"
              >{{copyNamespace.copy_namespace}}</button>
            </span>
          </div>
        </div>
        <small *ngIf="namespaceIsSelected()" class="form-text text-muted">
          Organization: {{selectedNamespace.target_org_name}}
          <br>
          Space: {{selectedNamespace.target_space_name}}
        </small>
      </div>
    </div>

    <div class="mb-3">
      Do you want to copy the {{isBatch ? this.itemCount + ' selected items' : 'item'}}? This may overwrite changes!
      <div class="text-end">
        <ng-container>
          <span ngbPopover="Select a namespace"
                [disablePopover]="namespaceIsSelected()"
                triggers="mouseenter:mouseleave"
                placement="bottom"
                class="d-inline-block">
            <button (click)="activeCopyItemModal.close(CloseReason.CONFIRM)"
                    [disabled]="!namespaceIsSelected()"
                    class="btn btn-primary me-1"
                    type="button">OK
            </button>
          </span>
          <button (click)="activeCopyItemModal.close(CloseReason.CANCEL)" class="btn btn-outline-secondary"
                  type="button">Cancel
          </button>
        </ng-container>
      </div>
    </div>
  </form>
</div>
